import React, {Component} from 'react';
import {withGlobalProps, ProvidedGlobalProps} from '../../../providers/globalPropsProvider';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {SubscriptionPlan, ISubscribePlan} from '../SubscriptionPlan/SubscriptionPlan';
import s from './SubscriptionPlans.scss';

export const enum SubscriptionPlansDataHooks {
  TITLE = 'plans-title',
}

export interface ISubscriptionPlansState {
  plans: ISubscribePlan[];
}

export interface ISubscriptionPlansProps extends ProvidedGlobalProps, IProvidedTranslationProps {}

@withGlobalProps
@withTranslations('globals.texts')
export class SubscriptionPlans extends Component<ISubscriptionPlansProps, ISubscriptionPlansState> {
  public state = {
    plans: [],
  };

  public componentDidMount() {
    const plans: ISubscribePlan[] = this.props.globals.getSubscriptionPlans();
    this.setState({plans});
  }

  private onItemClicked(item: ISubscribePlan) {
    const plans = this.state.plans;
    plans.forEach(plan => {
      plan.isSelected = false;
    });
    item.isSelected = true;
    this.setState({plans});
  }

  public render() {
    const {t} = this.props;

    return (
      <div>
        <div className={s.title} data-hook={SubscriptionPlansDataHooks.TITLE}>
          {t('PRODUCT_PAGE_PURCHASE_OPTIONS_TITLE')}
        </div>
        {this.state.plans.map((item, index) => (
          <SubscriptionPlan
            key={index}
            className={s.planItem}
            plan={item}
            isSelected={item.isSelected}
            onClick={() => {
              this.onItemClicked(item);
            }}></SubscriptionPlan>
        ))}
      </div>
    );
  }
}
